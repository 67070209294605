.switch {
  position: relative;
  display: inline-block;
  width: rem(40);
  height: rem(17);
  float: right;
}

.switch #edit-mode-slider {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: rem(13);
  width: rem(13);
  left: rem(2);
  bottom: rem(2);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

#edit-mode-slider:checked + .slider {
  background-color: $color-primary;
}

#edit-mode-slider:focus + .slider {
  box-shadow: 0 0 rem(1) $color-primary;
}

#edit-mode-slider:checked + .slider::before {
  -webkit-transform: translateX(rem(22));
  -ms-transform: translateX(rem(22));
  transform: translateX(rem(22));
}

.slider.round {
  border-radius: rem(34);
}

.slider.round::before {
  border-radius: 50%;
}

.box-edit-icon , .box-add-icon {
  fill: $color-primary;

  &:hover {
    fill: gray;
    transition: 0.3s;
  }
}
