// stylelint-disable no-empty-source

@import "_main";

// Gulp looks for variables in the following files in the specified order.
// 1. In the NodeJS package X-Net Rocket SCSS _variables.scss
// 2. In the project.json in the project root
// 3. In the custom_variables.scss
@import "_custom_variables";
@import "sidemenu";
@import "create_assistant_form";
@import "overview_personal_data";
@import "switch";
@import "wizard_navigation";
@import "employee";
@import "customer";
@import "dynamic_forms";
@import "dashboard_widget";
@import "pdf_export";

//------------------------------------------------------------------------------
// LOGIN-AS
li:has(a.active) {
  .side-nav-link {
    fill: var(--x-navbar-hover-color);
  }
}

.btn-tertiary {
  background-color: $color-primary;
}

.bg-tertiary {
  background-color: $color-primary;
}

.fixed_alert_container {
  background-image: linear-gradient(to bottom, #FCF8E3 0%, #F8EFC0 100%);
  background-repeat: repeat-x;
  border-color: #F5E79E;
  font-size: rem(15);
  padding: rem(9);
}

.svg-inline {
  height: 1.25em;
  vertical-align: -0.225em;
  width: 1.25em;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.shadow-lg {
  box-shadow: 0 rem(10) rem(15) rem(-3) rgba(0, 0, 0, 0.1), 0 rem(4) rem(6) rem(-4) rgba(0, 0, 0, 0.1);
}

p {
  margin-bottom: 0;
}

.rounded-tw {
  border-radius: 0.75rem;
}

.uppercase {
  text-transform: uppercase;
}

.inner-shadow-tw {
  box-shadow: inset 0 rem(2) rem(4) 0 rgba(0, 0, 0, 0.05);
}

.mt-n-1 {
  margin-top: -0.5em;
}

.mt-n-2 {
  margin-top: -1em;
}

.h-70vh {
  height: 70vh;
}

.text-sm {
  font-size: rem(13);
}

.text-lg {
  font-size: rem(18);
}

.font-bold {
  font-weight: normal;
}

.font-bolder {
  font-weight: bold;
}

.scroll-mh-65vh {
  max-height: 65vh;
  overflow: auto;
  overflow-x: hidden;
}

.border-box-left {
  border-left: rem(4) solid $color-primary;
}

.login-item-md {
  max-width: 40rem;
}

.inline-image {
  display: inline-block;
  position: relative;
  width: 100%;
}

.user-profile-picture-preview {
  img {
    border-radius: 50%;
    height: rem(110);
    width: rem(110);

    &:hover {
      background-color: gray;
      filter: grayscale(100%);
    }
  }

  .icon {
    top: rem(39);
    left: rem(41);
    opacity: 0;
    fill: white;
  }

  &:hover {
    .icon {
      opacity: 1;
    }
  }
}

.container-max-fluid {
  max-width: initial;
}

//------------------------------------------------------------------------------
// User Role Selection
.user-role-selection {
  a {
    font-weight: bold;
    cursor: default;

    &:hover {
      cursor: pointer;
      transition: 0.2s;
      background-color: $white;

      /* stylelint-disable declaration-no-important */
      color: $color-primary !important;

      /* stylelint-enable declaration-no-important */
    }
  }

  a.selected {
    background-color: $color-primary;
    border-color: $color-primary;
  }
}

.float-right {
  float: right;
}

.dynamic-form-content {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.form-group:has(> div.remove-label) {
  label {
    display: none;
  }
}

header {
  background-color: #eff1f6;
}

.fc-event {
  border-radius: rem(4);
}

.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.gap-x-1 {
  column-gap: 0.25rem;
}

.rounded-full {
  border-radius: 50%;
}

.fill-success {
  fill: #90AD25;
}

.btn:focus {
  box-shadow: 0 0 0 0.1875rem rgba(0, 104, 147, 0.5);
  outline: 0;
}

.fill-danger {

  /* stylelint-disable declaration-no-important */
  fill: #DC3545 !important;

  /* stylelint-enable declaration-no-important */
}

.fill-warning {
  fill: #FFC107;
}

.fill-white {
  fill: #FFF;
}

.text-white {
  color: white;
}

.pr-1295 {
  padding-right: 1.295em;
}

.pl-1-3 {
  padding-left: 1.3em;
}

.fix-height-110 {
  height: rem(110);
}

.modal-error-xl {
  .modal-content {
    max-width: 90%;
  }
}

.modal-body {
  form {
    padding: 0.2em;
  }
}

// ------------------------------------------------------
// Support hint
.support-hint {
  position: absolute;
  bottom: 0;
  right: rem(50);
}

.support-image {
  max-width: rem(450);
}

@media only screen and (max-width: rem(650)) {
  .footer-side-nav {
    position: fixed;
    bottom: 0;
  }

  .support-image {
    max-width: 95%;
  }
}
