.archive-base {
  .active {

    /* stylelint-disable declaration-no-important */
    background-color: #e7e7e7 !important;

    /* stylelint-enable declaration-no-important */
  }

  [data-request-target] {
    &:hover {

      /* stylelint-disable declaration-no-important */
      background-color: #e7e7e7 !important;
      transition: 0.2s;

      /* stylelint-enable declaration-no-important */
      cursor: pointer;
    }
  }
}
