:root {
  --x-side-nav-expanded-width: 4.5rem;
  --x-side-nav-width: 4.5rem;
  --x-side-menu-margin-expanded: 1rem;
  --x-side-nav-transition-time: 0.3s;
  --x-side-nav-background-color: $color-primary;
  --x-side-nav-medium-size-expanded: false;
  --x-navbar-color: #fff;
  --x-navbar-hover-color: #FA8C45;
}

/* stylelint-disable declaration-no-important */
.overflow-hidden {
  overflow: unset !important;
}

/* stylelint-enable declaration-no-important */
.navbar {
  .btn {
    padding: 0.2em 0.5em;
  }
}

.sidenav {
  background-color: $color-primary;

  ul {
    #expand-sidenav {
      height: rem(52);
      display: none;
    }

    .colored-header-spacer {
      margin: 0;
    }

    .active {
      a {
        fill: $color-secondary;
      }
    }

    .user-profile-picture {
      border-radius: 50%;
      height: rem(55);
      width: rem(55);
      background-color: $white;

      b {
        color: black;
      }

      p {
        border-radius: 50%;
      }

      img {
        margin: auto;
        height: 101%;
        width: 101%;
        border-radius: 50%;
      }

      .icon {
        top: rem(14);
        left: rem(17);
        opacity: 0;
        fill: white;
      }

      &:hover {
        background-color: gray;
        transition: 0.2s;

        .icon {
          top: rem(14);
          left: rem(17);
          opacity: 1;
        }

        img {
          filter: grayscale(100%);
          transition: 0.2s;
        }
      }
    }

    li {
      position: relative;

      .hover-title-text {
        border-radius: var(--x-border-radius);
        display: none;
        position: absolute;
        left: 103%;
        top: -0.25rem;
        width: max-content;
        padding: 0.5rem 1rem;
        background-color: $color-primary;
        color: $white;
        font-weight: bold;
        box-shadow: 0 0.625rem 0.938rem -0.188rem rgba(0, 0, 0, 0.4), 0 0.5rem 0.625rem -0.375rem rgba(0, 0, 0, 0.37);
      }

      &:hover .hover-title-text {
        display: block;
      }

      /* stylelint-disable declaration-no-important */
      .dropdown-menu-sidenav {
        left: 103% !important;
        top: rem(-5) !important;
      }

      /* stylelint-enable declaration-no-important */
      a {
        margin-right: auto;
        margin-left: auto;

        span {
          width: max-content;
        }

        .sidenav-link-text {
          display: none;
        }
      }
    }
  }
}

/* stylelint-disable declaration-no-important */
#role_box[aria-expanded="false"] {
  display: none !important;
}

#role_box[aria-expanded="true"] {
  display: flex;
}

#billing_config_changer_box[aria-expanded="false"] {
  display: none !important;
}

#billing_config_changer_box[aria-expanded="true"] {
  display: flex;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.3s;
}

.rotate-0 {
  transform: rotate(0deg);
  transition: 0.3s;
}

/* stylelint-enable declaration-no-important */
@media only screen and (max-width: 75rem) {
  .sidenav {
    ul {
      #expand-sidenav {
        display: flex;
      }
    }
  }

  .sidenav[sidemenu-expanded="true"] {
    width: 11.5rem;

    ul {
      li {

        /* stylelint-disable declaration-no-important */
        .dropdown-menu-sidenav {
          left: 100% !important;
        }

        /* stylelint-enable declaration-no-important */
        a {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 61.938rem) {

  /* stylelint-disable declaration-no-important */
  .overflow-hidden {
    overflow: hidden !important;
  }

  /* stylelint-enable declaration-no-important */
  .sidenav {
    ul {
      li {
        &[sidemenu-expanded="true"] {
          padding-right: var(--x-side-menu-margin-expanded);
        }

        &[sidemenu-expanded="false"] {
          margin-left: 0;
          margin-right: 0;
        }

        &:hover .hover-title-text {
          display: none;
        }
      }
    }
  }
}
