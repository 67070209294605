#tab-data {
  .tab-btn-box {
    font-weight: bold;

    button {
      border: none;
      background-color: gray;
    }

    .active {
      color: white;
      background-color: $color-primary;
    }


    .btn-archive {
      color: white;
      border-top-right-radius: 0.3em;
      border-bottom-right-radius: 0.3em;
    }

    .btn-active {
      color: white;
      border-bottom-left-radius: 0.3em;
      border-top-left-radius: 0.3em;
    }

    .btn-future {
      color: white;
    }
  }

  .level-of-employments-container {
    display: flex;
    align-items: center;
    padding-bottom: rem(8);
  }

  .level-of-employment-box {
    a {
      text-decoration: none;
    }
    color: white;
    background-color: rgba(23, 31, 57, 0.95);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: rem(72);
    height: rem(72);
    padding-top: rem(2);
    padding-bottom: rem(2);
  }

  .level-of-employment-value {
    max-height: 4rem;
    overflow: auto;
    font-weight: normal;
    max-width: 70rem;
  }

  .level-of-employment-box-hour {
    font-size: rem(30);
    align-content: center;
    align-items: center;
    text-align-all: center;
  }
}
