.loader {
  border: rem(8) solid #e5e5e5;
  border-top: rem(8) solid $color-primary;
  border-radius: 50%;
  width: rem(70);
  height: rem(70);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Overview Contact
// #############################################################################
//
.meta-data {
  .box-add-icon {
    display: none;
  }
}

hr {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.data-box-h {
  height: 14rem;

  a {
    background-color: $white;

    &:hover {
      background-color: rgba(204, 204, 204, 0.58);
    }
  }
}

.contact-note {
  width: 100%;
  max-height: 4em;
  overflow: scroll;
  position: relative;
  font-size: rem(13);

  &::-webkit-scrollbar {
    display: none;
  }
}

.contact-list {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 14rem;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;
    color: unset;
  }

  li {
    list-style: none;
  }
}

.contact-list-primary {
  color: $color-secondary;
}

.contact-list-icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
}

// Overview Location
// #############################################################################
//
.location-section {
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .location-box {
    flex-shrink: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .location-note {
    max-height: 3em;
    overflow: auto;
  }
}

.location-section > div {
  scroll-snap-align: start;
}

.scroll-prev {
  background-color: $color-primary;
  border-top-left-radius: rem(4);
  border-bottom-left-radius: rem(4);
  cursor: pointer;

  svg {
    height: 1.2em;
    width: 1.2em;
    fill: $white;
  }

  &:hover {
    background-color: rgba(23, 31, 57, 0.75);
    transition: 0.2s;
  }
}

.scroll-next {
  background-color: $color-primary;
  border-top-right-radius: rem(4);
  border-bottom-right-radius: rem(4);
  cursor: pointer;

  svg {
    height: 1.2em;
    width: 1.2em;
    fill: $white;
  }

  &:hover {
    background-color: rgba(23, 31, 57, 0.75);
    transition: 0.2s;
  }
}

.btn-disabled {
  background-color: gray;
  pointer-events: none;
}

// Overview Meeting Point
// #############################################################################
//
.meeting-point-section {
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .location-box {
    flex-shrink: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    height: 12em;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .location-note {
    max-height: 3em;
    overflow: auto;
  }

  .meeting-point-note {
    font-size: rem(13);
  }
}

.meeting-point-section > div {
  scroll-snap-align: start;
}

// Overview Notes
// #############################################################################
//
.note-parent-container {
  background: linear-gradient(135deg, #f5f588 81%, #f3f386 82%, #f5f591 82%, #ffffb7 100%);
  box-shadow: 1rem 0.625rem 0.9375rem -0.3875rem rgba(0, 0, 0, 0.1), rem(-7) -0.75rem 0.375rem -1.25rem rgba(0, 0, 0, 0.1);
  border-radius: rem(255) rem(15) rem(15) rem(225) rem(225) rem(15) rem(15) rem(177);
  max-width: 20rem;
  max-height: 8rem;
  transform: rotate(-0.5deg);

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: rem(0) rem(13) rem(8) rem(13);
    border-color: transparent transparent rgba(227, 227, 124, 0.86) transparent;
    transform: rotate(27deg);
    left: rem(0);
    bottom: rem(4);
    position: absolute;
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1rem 1rem 0;
    border-color: #eff1f6 transparent transparent;
    transform: rotate(20deg);
    left: rem(-8);
    bottom: rem(-9);
    position: absolute;
  }
}

.note-container {
  height: 8rem;
  overflow: auto;
}

.note-section {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  .note-scrollbox {
    &:nth-child(1n) {
      background: linear-gradient(135deg, #f5f588 81%, #f3f386 82%, #f5f591 82%, #ffffb7 100%);
      box-shadow: -1rem 0.625rem 0.9375rem -0.3875rem rgba(0, 0, 0, 0.1), rem(-7) -0.75rem 0.375rem -1.25rem rgba(0, 0, 0, 0.1);
      border-radius: rem(255) rem(15) rem(15) rem(225) rem(225) rem(15) rem(15) rem(177);

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 rem(13) rem(8) rem(13);
        border-color: transparent transparent rgba(227, 227, 124, 0.86);
        transform: rotate(320deg);
        right: rem(-1);
        bottom: rem(8);
        position: absolute;
      }

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.8rem 0 2rem 2rem;
        border-color: transparent transparent transparent #eff1f6;
        transform: rotate(45deg);
        right: rem(-22);
        bottom: rem(-19);
        position: absolute;
      }
    }

    &:nth-child(2n) {
      background: linear-gradient(90deg, #f5f588 81%, #f3f386 82%, #f5f591 82%, #ffffb7 100%);

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-color: transparent transparent transparent rgba(227, 227, 124, 0.86);
        transform: rotate(225deg);
        right: 0.3rem;
        bottom: 0;
        position: absolute;
      }

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 2.9375rem 0 2.9375rem 2.9375rem;
        border-color: transparent transparent transparent #eff1f6;
        transform: rotate(45deg);
        right: -3.8rem;
        bottom: -2rem;
        position: absolute;
      }
    }

    &:nth-child(3n) {
      background: linear-gradient(240deg, #f5f588 81%, #f3f386 82%, #f5f591 82%, #ffffb7 100%);
      box-shadow: rem(32) rem(10) rem(15) rem(-30) rgba(0, 0, 0, 0.1), 0 rem(4) rem(6) rem(-4) rgba(0, 0, 0, 0.1);

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem(0) rem(13) rem(8) rem(13);
        border-color: transparent transparent rgba(227, 227, 124, 0.86) transparent;
        transform: rotate(27deg);
        left: rem(0);
        bottom: rem(4);
        position: absolute;
      }

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 1rem 1rem 0;
        border-color: #eff1f6 transparent transparent;
        transform: rotate(20deg);
        left: rem(-8);
        bottom: rem(-9);
        position: absolute;
      }
    }

    &:nth-child(4n) {
      background: linear-gradient(90deg, #f5f588 81%, #f3f386 82%, #f5f591 82%, #ffffb7 100%);
    }

    &:nth-child(5n) {
      background: linear-gradient(135deg, #f5f588 81%, #f3f386 82%, #f5f591 82%, #ffffb7 100%);
    }

    &:nth-child(6n) {
      background: linear-gradient(135deg, #f5f588 81%, #f3f386 82%, #f5f591 82%, #ffffb7 100%);
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .note-box {
    height: 9rem;
    overflow: auto;
    flex-shrink: 0;
    transition: width 0.3s;

    hr {
      padding-top: 0.3em;
      padding-bottom: 0.3em;
    }

    .note-date {
      font-size: rem(11.5);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:focus {
      width: 100%;
    }
  }

  & > div {
    scroll-snap-align: start;
  }
}

// Overview Related Companies
// #############################################################################
//
.company-section {
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .related-companie-box {
    flex-shrink: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    height: 12em;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .location-note {
    max-height: 3em;
    overflow: auto;
  }
}

.related-companies-section > div {
  scroll-snap-align: start;
}

.care-level-old-box {
  max-height: 8rem;
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.archive-c2a-assistance {
  max-height: 30vh;
  overflow: auto;
}

.archive-hourly-rate {
  max-height: 30vh;
  overflow: auto;
}

.active-assistance {
  max-height: 30vh;
  overflow: auto;
}

.active-hourly-rate {
  max-height: 30vh;
  overflow: auto;
}

.archive-data {
  overflow: auto;

  p {
    color: black;
  }

  a {
    color: black;
    border-left: rem(4) solid $color-primary;
  }

  .box-edit-icon {
    border: none;
  }

  div {
    &:focus-visible {
      border-bottom: rem(2) solid $color-primary;
      border-right: rem(2) solid $color-primary;
      border-top: rem(2) solid $color-primary;
    }
  }

  .active {
    background-color: $color-selected;
    border-left: rem(4) solid $color-secondary;
  }
}


.scroll-btns {
  button {
    border: 0;

    &:focus {
      box-shadow: 0 0 0 0.1875rem $color-selected;
      outline: 0;
    }
  }
}

.scroll-mh-80vh {
  overflow: auto;
  overflow-x: hidden;
  max-height: 80vh;
}

.scroll-mh-60vh {
  overflow: auto;
  overflow-x: hidden;
  max-height: 60vh;
}

.scroll-mh-40vh {
  overflow: auto;
  overflow-x: hidden;
  max-height: 40vh;
}

.scroll-mh-35vh {
  overflow: auto;
  overflow-x: hidden;
  max-height: 35vh;
}

.scroll-mh-30vh {
  overflow: auto;
  overflow-x: hidden;
  max-height: 30vh;
}

.repeat-icon {
  height: 0.8rem;
  width: 0.8rem;
}

.bg-color-primary {
  background-color: $color-primary;
}
