.wizard-navigation {
  div {
    a {
      color: $color-primary;
      font-weight: bold;
      cursor: default;
      border-bottom: rem(1) solid rgba(0, 0, 0, 0);

      &:hover {
        color: $color-secondary;
        cursor: pointer;
        transition: 0.2s;
        border-bottom: rem(1) solid $color-secondary;
      }
    }

    .active {
      color: $color-secondary;
      border-bottom: rem(1) solid $color-secondary;
    }
  }
}
