.tab-menu-form {
  .form-tab {
    cursor: pointer;
    border-bottom: rem(1) solid var(--x-gray-500);

    &:hover {
      transform-origin: top left;
    }
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .active {
    background-color: white;
    color: black;
    transform-origin: top left;
    border-top: rem(1) solid var(--x-gray-500);
    border-left: rem(1) solid var(--x-gray-500);
    border-right: rem(1) solid var(--x-gray-500);
    border-bottom: rem(0) solid var(--x-gray-500);
  }

  .scale-right {
    scale: 1.13;
    transform-origin: left;
  }

  .scale-left {
    scale: 1.13;
    transform-origin: right;
  }
}
