.shortcut-btn {
  display: flex;

  .icon {
    fill: white;
  }

  color: white;

  &:hover {
    color: white;
    fill: white;
    transition: 0.2s;
  }
}

.dashboard-box {
  height: 45.5vh;

  .box-content {
    height: 39vh;
    overflow-y: auto;
  }

  .toast {
    opacity: 100;
    max-width: initial;
  }

  .border-none {
    border: none;
  }

  .mark-as-read {
    background-color: green;
    padding: 0.1rem 0.2rem;
  }

  .icon {
    fill: $color-fancy-dark;
  }

  .icon-color-secondary {
    fill: $color-secondary;
  }

  a {
    color: $color-secondary;
    fill: $color-fancy-dark;
  }

  .dashboard-box-header-link {
    padding: 0.2em 0;
    color: $color-fancy-dark;
    border: rem(1) solid $color-fancy-dark;

    &:hover {
      transition: 0.2s;
      background-color: $color-fancy-dark;
      color: white;
    }
  }

  .widget-profile-picture {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3em;
    height: 3em;
    border: rem(1) solid $color-fancy-dark;
  }
}

.contact-info-modal {
  .modal-header {
    border-bottom: rem(1) $color-fancy-dark solid;
  }
}

@media only screen and (max-width: rem(1200)) {
  .dashboard-box {
    height: 58vh;

    .box-content {
      height: 51vh;
    }
  }
}

@media only screen and (max-width: 47.9375rem) {
  .dashboard-box {
    height: initial;
  }
}
